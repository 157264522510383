import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations';
import getters from './getters';
import state from './state';
import actions from './actions';
import ModuleArticle from './modules/article';
import ModulePayment from './modules/payment';
import ModuleNotifications from './modules/notifications';
import ModuleCoupon from './modules/coupon';

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    article: ModuleArticle,
    payment: ModulePayment,
    noti: ModuleNotifications,
    coupon: ModuleCoupon
  }
})
