const cordovaApp = {
  initialize() {
      console.warn(1)
      document.addEventListener("deviceready", this.onDeviceReady.bind(this), false);
  },

  async onDeviceReady() {
    //   console.log('Device is ready');
    //   console.log('Cordova:', window.cordova);
    //   console.log('Cordova plugins:', window.cordova.plugins);

      if (window.device) {
        //   console.log('Device plugin loaded successfully');
        //   console.log('Device model:', device.model);
        //   console.log('Device platform:', device.platform);
        //   console.log('Device version:', device.version);
      } else {
          console.error('Device plugin not loaded');
      }

      try {
          // const { data } = await apiWebSystems();
          // console.log("data.app_version", data[0].app_version);
          // window.cordova.getAppVersion.getVersionNumber().then(function(ver) {
          //     console.log("getAppVersion", ver);
          // });

          if (device.platform === "iOS") {
              window.cordova.exec(
                  function(res) {
                      console.log("success", JSON.stringify(res));
                  },
                  function fail(res) {
                      console.log("fail", JSON.stringify(res));
                  },
                  "idfa",
                  "requestPermission",
                  []
              );
          }

          this.initStatusBar();
          this.initWKWebView();
          this.initCustomUrlScheme();

      } catch (err) {
          console.error('Error:', err);
      }
  },

  initCustomUrlScheme() {
      window.handleOpenURL = (url) => {
          alert("DeepLink: " + url);
      };
  },

  initStatusBar() {
      if (window.StatusBar) {
          window.StatusBar.overlaysWebView(false);
          window.StatusBar.backgroundColorByHexString("#009896");
      }
  },

  initWKWebView() {},

  // versionCompare(v1, v2, options) {
      // ... version comparison logic
  // }
};

export default cordovaApp;
