const defaultParkingCoupons = [
  {
    id: 'e4524074-c4f4-4698-97fa-c17cabbcc2c8',
    title: "10 元雲林路邊停車折抵券",
    company: "惠隆資訊",
    type: "parking",
    pay: 10,
  },
  {
    id: 'b1635819-f6ce-4e5a-abdd-797017696f2a',
    title: "20 元雲林路邊停車折抵券",
    company: "惠隆資訊",
    type: "parking",
    pay: 20,
  },
  {
    id: '1193920c-0d09-4873-9126-6f427373305c',
    title: "30 元雲林路邊停車折抵券",
    company: "惠隆資訊",
    type: "parking",
    pay: 30,
  },
]
const getCouponFromLocalStorage = () => {
  const coupons = localStorage.getItem('UserCoupons') || '[]'
  return JSON.parse(coupons)
}

const state = () => ({
  parkingCoupons: defaultParkingCoupons,
  userCoupons: [],
})

const getters = {
  parkingCoupons(state) {
    return state.parkingCoupons
  },
  userCoupons(state) {
    return state.userCoupons
  },
}

const mutations = {
  setUserCoupons(state, coupons) {
    state.userCoupons = coupons
    localStorage.setItem('UserCoupons', JSON.stringify(coupons))
  }
}

const actions = {
  fetchUserCoupons({ commit }) {
    const coupons = getCouponFromLocalStorage()
    commit('setUserCoupons', coupons)
  },
  fetchUserCoupon(_params, id) {
    return new Promise((resolve, reject) => {
      const coupons = getCouponFromLocalStorage()
      const item = coupons.find(coupon => coupon.id === id)
      if (!item) {
        return reject(new Error('Coupon not found'))
      }
      return resolve(item)
    })
  },
  addUserCoupon({ commit, state }, coupon) {
    const isExist = state.userCoupons.some(c => c.id === coupon.id)
    if (isExist) return

    const coupons = [...state.userCoupons, coupon]
    commit('setUserCoupons', coupons)
  },
  updateUserCoupon({ commit, state }, coupon) {
    const coupons = state.userCoupons.map(c => {
      if (c.id === coupon.id) {
        return coupon
      }
      return c
    })
    commit('setUserCoupons', coupons)
  },
  removeUserCoupon({ commit, state }, coupon) {
    const coupons = state.userCoupons.filter(c => c.id !== coupon.id)
    commit('setUserCoupons', coupons)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};