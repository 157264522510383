<template>
    <div id="app">
        <router-view></router-view>

        <notifications group="noti-popup" position="top center" :max="1"
            width="96%" :duration="5000">
            <template slot="body" slot-scope="props">
                <div class="custom-template" @click="props.close">
                <div class="custom-template-content">
                    <div class="custom-template-title">
                    ｜{{props.item.title}}
                    </div>
                    <div class="custom-template-text"
                        v-html="props.item.text">
                    </div>
                    <!-- <div class="custom-template-text text-wrap">
                        {{ JSON.stringify(props.item) }}
                    </div> -->
                </div>
                <!-- <div class="custom-template-close"
                    @click="props.close">
                </div> -->
                <div class="custom-template-icon">
                    <div class="img"><img src="@/assets/icon/point.svg" /></div>
                </div>
                </div>
            </template>
        </notifications>
        <notifications group="normal" class="normal-notification" width="120" />
    </div>
</template>

<script>
window.addEventListener(
    "resize",
    (function () {
        document.documentElement.style.setProperty(
            "--app-height",
            `${window.innerHeight}px`
        );
    })()
);

export default {
    name: "App"
};
</script>

<style scoped lang="scss">
.custom-template {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 13px;
  margin: 5px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  &, & > div {
    box-sizing: border-box;
  }
//   background: #f9e8f0;
  background: #fffeff;
  border: 2px solid #cdcdcd;
  border-radius: 16px;
  .custom-template-icon {
    width: 20%;
    flex: 0 1 auto;
    // color: #15C371;
    font-size: 32px;
    padding: 0 10px;
  }
  // .custom-template-close {
  //   color:#495061;
  //   flex: 0 1 auto;
  //   padding: 0 20px;
  //   font-size: 16px;
  //   opacity: 0.2;
  //   cursor: pointer;
  //   &:hover {
  //     opacity: 0.8;
  //   }
  // }
  .custom-template-content {
    width: 80%;
    padding: 10px;
    flex: 1 0 auto;
    color: #495061;
    .custom-template-title {
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      // word-wrap: break-word;
      color: #5CB8B2;
    }
    .custom-template-text {
      font-size: 16px;
    }
  }
}

.normal-notification {
  margin-top: 24px;
}
</style>