import axios from 'axios'

const actions = {
  getUserPoints({state, commit}) {
    const config = {
      method: "get",
      url: `${process.env.VUE_APP_API_HOST}/points/v1/merchants/${process.env.VUE_APP_MERCHANT_ID}/members/${state.user.userId}/points`,
    };

    return axios(config)
      .then(function (response) {
        commit('updateUserPoints', response.data);
      })
      .catch(function (error) {
        console.log('3991', error);
      });
  }
}

export default actions